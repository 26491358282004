module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"ssr":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Osiedle pod miastem Kunów","short_name":"Kunów","start_url":"/","background_color":"#fff","theme_color":"#7bb933","display":"minimal-ui","icon":"src/assets/images/favicons/favicon-32x32.png","icons":[{"src":"src/assets/images/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/assets/images/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/assets/images/favicons/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"src/assets/images/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/images/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"91eb83285fc5dbd80beb89925f93046c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
